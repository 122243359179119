export default [
  // {
  //   header: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Home',
  //       route: 'escritorio',
  //       icon: 'HomeIcon',
  //     },
  //     {
  //       title: 'Second Page',
  //       route: 'second-page',
  //       icon: 'FileIcon',
  //     },
  //   ],
  // },
  {
    title: 'Escritorio',
    route: 'escritorio',
    icon: 'HomeIcon',
  },
  {
    header: 'Modulo Inventario',
    title: 'Productos',
    icon: 'PackageIcon',
    slug: '',
    children: [
      {
        title: 'Productos',
        route: 'administracion-productos',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Categorias',
        route: 'administracion-categorias',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Marcas',
        route: 'administracion-marcas',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Ubicaciones',
        route: 'administracion-ubicaciones',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Kardex',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      // {
      //   title: 'Adicionales',
      //   route: 'administracion-adicionales',
      //   slug: ''
      // },
      // {
      //   title: 'Variaciones',
      //   route: 'administracion-variaciones',
      //   slug: ''
      // },
    ],
  },
  {
    header: 'Modulo Compras',
    title: 'Compras',
    icon: 'PackageIcon',
    slug: '',
    children: [
      {
        title: 'Listado de Compras',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Nueva Compra',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Proveedores',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Pedidos',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Cuentas por pagar',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
    ],
  },
  {
    header: 'Modulo Ventas',
    title: 'Ventas',
    icon: 'PackageIcon',
    slug: '',
    children: [
      {
        title: 'Listado de Ventas',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Nueva Venta',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Clientes',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Cotizaciones',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Cuentas por cobrar',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
    ],
  },
  {
    title: 'Facturas',
    route: '#',
    icon: 'FileTextIcon',
  },
  {
    title: 'Arqueo de Caja',
    route: '#',
    icon: 'HomeIcon',
  },
  {
    header: 'Reportes',
    title: 'Reportes',
    icon: 'PackageIcon',
    slug: '',
    children: [
      {
        title: 'Reporte de Inventario',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Reporte de Compras',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Reporte de Ventas',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Reporte de Caja',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
    ],
  },
  {
    header: 'Modulo Configuracion',
    title: 'Configuracion',
    icon: 'PackageIcon',
    slug: '',
    children: [
      {
        title: 'Empresa',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Roles y Permisos',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Usuarios',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Copias de Seguridad',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Auditoria',
        route: '#',
        slug: '',
        icon: 'FileIcon',
      },
      {
        title: 'Configuracion SIN',
        route: '#',
        slug: '',
        icon: 'SettingsIcon',
      },
    ],
  },
]
